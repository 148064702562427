@import '~react-toastify/dist/ReactToastify.css';
@import "~bootstrap/dist/css/bootstrap.min.css";
@import url("https://cdn.syncfusion.com/ej2/bootstrap4.css");

@import './colors.css';

.ow_catalog_hmtr {
    margin-top: 0.5rem;
}

.ow_catalog_mtr {
    margin-top: 1rem;
}

.ow_catalog_atr {
    text-align: right;
}

.ow_catalog_coi {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--busy--color);
    float: right;
}

.ow_catalog_dds {
    border-bottom: 1px solid #007bff;
}

input[type="color"] {
    width: 3rem !important;
}

.ow_catalog_halfbold {
    font-weight: 500;
}

.margin_top_row {
    margin-top: 1rem;
}